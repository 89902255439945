import Vue from 'vue';
import { mapGetters } from 'vuex';
import { PageState } from './types';
import { PrError } from '@/component/pr-error';

const PrPage = Vue.extend({
  components: {
    PrError,
  },

  data() {
    return {
      page: {
        state: 0,
        errorMsg: 'Something has gone wrong :(',
      },
      state: {
        initial: 0,
        loading: 1,
        success: 2,
        uploading: 3,
        uploaded: 4,
        error: 5,
      } as PageState,
    };
  },

  computed: {
    ...mapGetters('overlay', ['pluginContext']),

    isLoading(): boolean {
      return this.page.state === this.state.loading;
    },

    isSuccess(): boolean {
      return this.page.state === this.state.success;
    },

    isUploading(): boolean {
      return this.page.state === this.state.uploading;
    },

    isUploaded(): boolean {
      return this.page.state === this.state.uploaded;
    },

    isError(): boolean {
      return this.page.state === this.state.error;
    },
  },

  methods: {
    stateLoading(): void {
      this.changeState(this.state.loading);
    },

    stateSuccess(): void {
      this.changeState(this.state.success);
    },

    stateUploading(): void {
      this.changeState(this.state.uploading);
    },

    stateUploaded(): void {
      this.changeState(this.state.uploaded);
    },

    stateError(error: Error) {
      this.changeState(this.state.error);
      if (error) {
        this.page.errorMsg = error.message;
        console.error(error);
      }
    },

    changeState(state: number): void {
      if (this.isStateValid(state)) {
        this.page.state = state;
      } else {
        throw new Error(`Unknown state provided: ${state}`);
      }
    },

    isStateValid(state: number): boolean {
      return Object.values(this.state).includes(state);
    },
  },
});

export { PrPage };
export default PrPage;
